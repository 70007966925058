import "./NewsletterForm.css"
import React from "react"

export default ({ header }) => (
  <div>
    <script src="https://f.convertkit.com/ckjs/ck.5.js"></script>
    <h2 style={{ textAlign: "center" }}>
      {header || "Be the first to know about my posts!"}
    </h2>
    <form
      action="https://app.convertkit.com/forms/1040940/subscriptions"
      className="seva-form formkit-form"
      method="post"
      data-sv-form="1040940"
      data-uid="3ed27c11c4"
      style={{ margin: "20px auto", textAlign: "center" }}
      data-options='{"settings":{"after_subscribe":{"action":"message","redirect_url":"","success_message":"Success! Now check your email to confirm your subscription."},"return_visitor":{"action":"show","custom_content":""},"recaptcha":{"enabled":false}}}'
    >
      <input
        className="formkit-input"
        aria-label="Your first name"
        name="fields[first_name]"
        placeholder="Your first name"
        type="text"
        style={{
          color: "rgb(0, 0, 0)",
          borderColor: "rgb(227, 227, 227)",
          borderRadius: "4px",
          fontWeight: "400",
          margin: "5px",
        }}
      />
      <input
        className="formkit-input"
        name="email_address"
        placeholder="Your email address"
        required=""
        type="email"
        style={{
          color: "rgb(0, 0, 0)",
          borderColor: "rgb(227, 227, 227)",
          borderRadius: "4px",
          fontWeight: "400",
          margin: "5px",
        }}
      />
      <button
        data-element="submit"
        className="formkit-submit formkit-submit"
        style={{
          color: "rgb(255, 255, 255)",
          backgroundColor: "rgb(0, 121, 148)",
          borderRadius: "4px",
          fontWeight: "400",
          margin: "5px auto 20px auto",
          textAlign: "center",
          display: "block",
        }}
      >
        <span>Subscribe!</span>
      </button>
    </form>
  </div>
)
