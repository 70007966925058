import React from 'react'
import Layout from '../components/Layout'
import NewsletterForm from '../components/NewsletterForm'

export default function Subscribe () {
  return (
    <Layout>
      <div className='post container' style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <NewsletterForm header={"Let's keep the conversation going!"} />
      </div>
    </Layout>
  )
}
